




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { buildLeaderboard } from '@/firebase/functions';
import { BuildLeaderboardDto, Series } from '@/models';

@Component
export default class RebuildLeaderboard extends Vue {
  @Prop({ required: true }) readonly orgId!: string;
  @Prop({ required: true }) readonly series!: Series;

  dialog = false;
  loading = false;
  error = false;

  async rebuildLeaderboard() {
    try {
      this.error = false;
      this.loading = true;
      await buildLeaderboard(new BuildLeaderboardDto(this.orgId, this.series.id));
    } catch (error) {
      this.$store.dispatch('errorModal/showDialog', {
        title: 'Error rebuilding leaderboard',
        message: `Failed rebuilding leaderboard for series: ${this.series?.name}`,
      });
      this.error = true;
      this.dialog = false;
    } finally {
      this.loading = false;
    }
  }
}
